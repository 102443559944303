.blog-card-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.blog-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  height: 400px; /* Fixed height for all cards */
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.blog-card-image-container {
  width: 100%;
  height: 200px; /* Fixed height for image container */
  position: relative;
  overflow: hidden;
}

.blog-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-content {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
  line-height: 1.4;
  height: 3.5em; /* Approximately 2 lines of text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-card-date {
  font-size: 0.875rem;
  color: #666;
}

.blog-card-read-more {
  font-size: 0.875rem;
  color: #0066cc;
  font-weight: 600;
}

@media (min-width: 640px) {
  .blog-card {
    margin: 0 auto;
  }
}

/* Styles for blog detail page cards */
.blog-detail-card.blog-card {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 400px; /* Increased width */
}

.blog-detail-card .blog-card-image-container {
  width: 120px; /* Slightly larger image */
  height: 120px;
  flex-shrink: 0;
  margin-right: 1rem;
}

.blog-detail-card .blog-card-content {
  padding: 0;
  flex-grow: 1;
}

.blog-detail-card .blog-card-title {
  font-size: 1rem;
  height: auto;
  margin-bottom: 0.25rem;
}

.blog-detail-card .blog-card-footer {
  flex-direction: column;
  align-items: flex-start;
}

.blog-detail-card .blog-card-date {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.blog-detail-card .blog-card-read-more {
  font-size: 0.75rem;
}
