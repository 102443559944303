.contact-us-section {
  background-color: #f8f9fa;
  color: #333;
  padding: 6rem 0 8rem; /* Increased bottom padding from 6rem to 8rem */
}

.section-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333333; /* Changed to dark grey */
}

.section-description {
  font-size: 1.2rem;
  color: #666666; /* Changed to medium grey */
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.action-btn {
  font-size: 1.1rem;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  margin-right: 0.75rem;
  font-size: 1.4rem;
  width: 1.4rem;
  display: inline-block;
  text-align: center;
}

.action-btn.btn-primary {
  background-color: #4472ca;
  border-color: #4472ca;
}

.action-btn.btn-primary:hover {
  background-color: #3a62b0;
  border-color: #3a62b0;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.action-btn.btn-outline-primary {
  color: #4472ca;
  border-color: #4472ca;
}

.action-btn.btn-outline-primary:hover {
  background-color: #4472ca;
  color: #fff;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  .section-title {
    font-size: 2.5rem;
  }

  .section-description {
    font-size: 1.1rem;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .action-btn {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 576px) {
  .contact-us-section {
    padding: 4rem 0 6rem; /* Increased bottom padding from 4rem to 6rem for mobile */
  }

  .section-title {
    font-size: 2rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .action-btn {
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
  }
}
