/* BlogDetailPage styles */
.blog-detail-container {
  display: flex;
  max-width: 1240px; /* Increased max-width */
  margin: 0 auto;
  padding: 2rem 1rem;
}

.blog-detail-main-content {
  flex: 1;
  max-width: 750px; /* Increased max-width */
  margin-right: 2rem;
  margin-top: 3rem; /* Add top margin to move content down */
}

.blog-detail-sidebar {
  width: 320px; /* Slightly increased width */
  margin-top: 3rem; /* Add top margin to align with main content */
  padding-left: 60px; /* Add left padding to move cards to the right */
}

.blog-detail-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 1rem;
}

.blog-detail-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #718096;
}

.blog-detail-date,
.blog-detail-author {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.blog-detail-author {
  font-weight: 500;
}

.blog-detail-image {
  width: 100%;
  max-height: 25rem;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.blog-detail-content {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
}

.blog-detail-content p {
  margin-bottom: 1.25rem;
}

.blog-detail-content h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3182ce;
  padding-bottom: 0.5rem;
}

.blog-detail-content h3 {
  font-size: 1.75rem;
  font-weight: bold;
  color: #2d3748;
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
}

.blog-detail-content h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a5568;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.blog-detail-content h5 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4a5568;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.blog-detail-content ul,
.blog-detail-content ol {
  margin-bottom: 1.25rem;
  padding-left: 1.5rem;
}

.blog-detail-content li {
  margin-bottom: 0.5rem;
}

.blog-detail-content a {
  color: #3182ce;
  text-decoration: underline;
}

.blog-detail-content a:hover {
  color: #2c5282;
}

/* Loading spinner */
.blog-detail-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.blog-detail-spinner {
  animation: spin 1s linear infinite;
  border-top: 2px solid #3182ce;
  border-right: 2px solid #3182ce;
  border-bottom: 2px solid #3182ce;
  border-left: 2px solid transparent;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error message */
.blog-detail-error {
  text-align: center;
  margin-top: 2rem;
  color: #e53e3e;
  font-size: 1.125rem;
}

/* Back to Blogs button */
.blog-detail-back-link {
  display: inline-block;
  margin-bottom: 1rem;
  text-decoration: none;
}

.blog-detail-back-button {
  display: flex;
  align-items: center;
  background-color: #3182ce;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.blog-detail-back-button:hover {
  background-color: #2c5282;
}

.blog-detail-back-icon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  fill: currentColor;
}

/* Blog description */
.blog-detail-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

/* Tags section */
.blog-detail-tags {
  margin-top: 1.5rem;
}

.blog-detail-tags h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.blog-detail-tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.blog-detail-tag {
  background-color: #e2e8f0;
  color: #4a5568;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

/* Back to Top button */
.blog-detail-back-to-top {
  text-align: center;
  margin-top: 2rem;
}

.blog-detail-back-to-top-button {
  background-color: #3182ce;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-detail-back-to-top-button:hover {
  background-color: #0056b3;
}

/* Share Section Styles */
.blog-detail-share {
  margin-bottom: 2rem;
}

.blog-detail-share-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
}

.blog-detail-share-icons {
  display: flex;
  gap: 1rem;
}

.blog-detail-share-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.blog-detail-share-icon:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.blog-detail-share-icon svg {
  width: 18px;
  height: 18px;
  fill: #ffffff;
}

.blog-detail-share-icon.email {
  background-color: #D44638;
}

.blog-detail-share-icon.linkedin {
  background-color: #0077B5;
}

.blog-detail-share-icon.facebook {
  background-color: #1877F2;
}

.blog-detail-share-icon.twitter {
  background-color: #1DA1F2;
}

.blog-detail-share-icon.whatsapp {
  background-color: #25D366;
}

/* Related Blog Cards Styles */
.blog-detail-sidebar {
  margin-top: 3rem;
}

.blog-detail-related-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
}

.blog-detail-related-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blog-detail-related-cards .blog-card,
.blog-detail-card {
  height: auto;
  max-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 180px; /* Further reduced width */
}

.blog-detail-related-cards .blog-card-image-container,
.blog-detail-card .blog-card-image-container {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}

.blog-detail-related-cards .blog-card-content,
.blog-detail-card .blog-card-content {
  padding: 0.2rem 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 45px); /* Adjust content width based on image size */
}

.blog-detail-related-cards .blog-card-title,
.blog-detail-card .blog-card-title {
  font-size: 0.7rem;
  margin-bottom: 0.05rem;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.blog-detail-related-cards .blog-card-footer,
.blog-detail-card .blog-card-footer {
  margin-top: 0.05rem;
}

.blog-detail-related-cards .blog-card-date,
.blog-detail-related-cards .blog-card-read-more,
.blog-detail-card .blog-card-date,
.blog-detail-card .blog-card-read-more {
  font-size: 0.55rem;
}

@media (max-width: 1024px) {
  .blog-detail-container {
    flex-direction: column;
  }

  .blog-detail-main-content {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .blog-detail-sidebar {
    width: 100%;
  }

  .blog-detail-related-cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .blog-detail-container {
    padding: 1.5rem 1rem;
  }

  .blog-detail-title {
    font-size: 1.75rem;
  }

  .blog-detail-content {
    font-size: 0.9375rem;
  }

  .blog-detail-content h2 {
    font-size: 1.375rem;
  }

  .blog-detail-content h3 {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 480px) {
  .blog-detail-container {
    padding: 1rem;
  }

  .blog-detail-title {
    font-size: 1.5rem;
  }

  .blog-detail-meta {
    flex-direction: column;
    align-items: flex-start;
  }

  .blog-detail-content {
    font-size: 0.875rem;
  }

  .blog-detail-content h2 {
    font-size: 1.25rem;
  }

  .blog-detail-content h3 {
    font-size: 1rem;
  }

  .blog-detail-back-to-top-button {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
  }
}
