.wave-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  #waves {
    width: 100%;
    height: 100%;
  }
  
  .stt-active #waves {
    animation: pulse 1s infinite alternate;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  