.playground-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 120px);
    position: relative;
    overflow: visible;
    background-color: transparent;
    padding: 60px 0;
  }
  
  .playground-title {
    color: inherit;
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .playground-waves {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 90%; /* Reduced from 100% to 80% */
    z-index: 1;
  }
  
  .playground-content {
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 600px;
    text-align: center;
    overflow-y: auto;
    max-height: 50vh;
  }
  
  .playground-text {
    margin-bottom: 10px;
    color: inherit;
  }
  
  .playground-error {
    margin-bottom: 10px;
    color: #f44336;
  }
  
  .playground-button-container {
    position: relative;
    z-index: 2;
    margin-top: auto;
  }
  
  .playground-button {
    border-radius: 4px;
    padding: 6px 16px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    min-width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .playground-button .MuiButton-startIcon {
    margin-right: 8px;
  }
  
  .playground-button .MuiSvgIcon-root {
    font-size: 24px;
  }
  
  @media (max-width: 600px) {
    .playground-container {
      height: calc(100vh - 80px);
      padding: 30px 0;
    }
  
    .playground-title {
      margin-bottom: 20px;
    }
  
    .playground-content {
      max-height: 40vh;
    }
  
    .playground-button {
      font-size: 14px;
      min-width: 180px;
      height: 50px;
      padding: 6px 12px;
    }
  
    .playground-button .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
  